@import url("./assets/css/bootstrap.min.css");
@import url("./assets/css/bootstrap_limitless.css");
@import url("./assets/css/components.min.css");
@import url("./assets/css/colors.min.css");
@import url("./assets/css/layout.css");
@import url("./assets/extras/animate.min.css");

/* icons  */
@import url("./assets/icons/icomoon/styles.min.css");
@import url("./assets/icons/fontawesome/styles.min.css");
@import url("./assets/icons/material/styles.min.css");

/* button loader */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.sidebar-user-material .sidebar-user-material-body {
  background: rgb(88, 202, 185);
  background-size: cover;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn .lds-ring {
  height: 19px !important;
  width: 19px !important;
}
.btn .lds-ring div {
  width: 15px;
  height: 15px;
  border-width: 2px;
  margin: 7px 10px 7px 10px;
}
/* remove x mark in error feedback */
.is-invalid {
  background: none !important;
}

input[type="file"] {
  position: relative;
  padding: 0.9375rem;
  color: #f0f0f0;
  background-color: #2c2c3c;
  cursor: pointer;
  border-radius: 0.3125rem;

  &::file-selector-button {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    color: #fff;
    background-color: #58cab9;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 1.5625rem;
    width: 0.9375rem;
    height: 1.25rem;
    transform: translateY(-50%);
    pointer-events: none;
    /* content: url("data:image/svg+xml,%0A%3Csvg%20id%3D%22Layer%5f1%22%20data-name%3D%22Layer%201%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2015.37%20188.41%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill%3A%23aeadad%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ccircle%20class%3D%22cls-1%22%20cx%3D%227.69%22%20cy%3D%227.69%22%20r%3D%227.69%22%2F%3E%3Crect%20class%3D%22cls-1%22%20x%3D%226.69%22%20y%3D%2227.72%22%20width%3D%222%22%20height%3D%22160.69%22%2F%3E%3C%2Fsvg%3E%0A"); */
  }
}
