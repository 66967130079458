.marks path {
  fill: #137b80;
  stroke: #c0c0bb;
}

.map-canvas {
  height: 480px;
  width: 960px;
  border: none;
  /* border-style: ridge; */
  border-color: #fff;
  color: #fff;
  /* background-color: "#D37506"; */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19); */
}
.map-details-canvas {
  height: 410px;
  width: 100%;
  border: none;
  border-color: #fff;
  color: #fff;
}

.path {
  fill: rgb(132, 0, 255);
  stroke: cornsilk;
  stroke-width: 0.5px;
}

.path:hover {
  cursor: pointer;
  fill: rgb(255, 145, 0);
  fill-opacity: 0.5;
}
#mapContainer {
  position: relative !important;
  min-height: 410px !important;
}
