
.s2 {
  color: #4aae9f;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 20.5pt;
}
.ft16{
  margin-bottom: -4px;
}
.receipt-content .logo a:hover {
  text-decoration: none;
  color: #7793c4;
}

.receipt-content .invoice-wrapper {
  background: #fff;
  /* border: 1px solid #cdd3e2;
  box-shadow: 0px 0px 1px #ccc; */
  padding: 10px 20px 0px;
  /* margin-top: 40px; */
  border-radius: 4px;
}

.receipt-content .invoice-wrapper .payment-details span {
  color: #a9b0bb;
  display: block;
}

.receipt-content .invoice-wrapper .payment-details a {
  display: inline-block;
  margin-top: 5px;
}

.receipt-content .invoice-wrapper .line-items .print a {
  display: inline-block;
  border: 1px solid #9cb5d6;
  padding: 13px 13px;
  border-radius: 5px;
  color: #708dc0;
  font-size: 13px;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.receipt-content .invoice-wrapper .line-items .print a:hover {
  text-decoration: none;
  border-color: #333;
  color: #333;
}

@media (min-width: 1200px) {
  .receipt-content .container {
    width: 100%;
  }
}

.receipt-content .logo {
  text-align: center;
  margin-top: 50px;
}

.receipt-content .logo a {
  font-family: Myriad Pro, Lato, Helvetica Neue, Arial;
  font-size: 36px;
  letter-spacing: 0.1px;
  color: #555;
  font-weight: 300;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.receipt-content .invoice-wrapper .intro {
  line-height: 25px;
  color: #444;
}

.receipt-content .invoice-wrapper .payment-info {
  margin-top: 25px;
  padding-top: 15px;
}

.receipt-content .invoice-wrapper .payment-info span {
  color: #a9b0bb;
}

.receipt-content .invoice-wrapper .payment-info strong {
  display: block;
  color: #444;
  margin-top: 3px;
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .payment-info .text-right {
    text-align: left;
    margin-top: 20px;
  }
}

.receipt-content .invoice-wrapper .payment-details {
  border-top: 2px solid #ebecee;
  margin-top: 30px;
  padding-top: 20px;
  line-height: 22px;
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .payment-details .text-right {
    text-align: left;
    margin-top: 20px;
  }
}

.receipt-content .invoice-wrapper .line-items {
  margin-top: 40px;
}

.receipt-content .invoice-wrapper .line-items .headers {
  color: #a9b0bb;
  font-size: 13px;
  letter-spacing: 0.3px;
  border-bottom: 2px solid #ebecee;
  padding-bottom: 4px;
}

.receipt-content .invoice-wrapper .line-items .items {
  margin-top: 8px;
  border-bottom: 2px solid #ebecee;
  padding-bottom: 8px;
}

.receipt-content .invoice-wrapper .line-items .items .item {
  padding: 10px 0;
  color: #696969;
  font-size: 15px;
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .items .item {
    font-size: 13px;
  }
}

.receipt-content .invoice-wrapper .line-items .items .item .amount {
  letter-spacing: 0.1px;
  color: #84868a;
  font-size: 16px;
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .items .item .amount {
    font-size: 13px;
  }
}

.receipt-content .invoice-wrapper .line-items .total {
  margin-top: 30px;
}

.receipt-content .invoice-wrapper .line-items .total .extra-notes {
  float: left;
  width: 40%;
  text-align: left;
  font-size: 13px;
  color: #7a7a7a;
  line-height: 20px;
}

@media (max-width: 767px) {
  .receipt-content .invoice-wrapper .line-items .total .extra-notes {
    width: 100%;
    margin-bottom: 30px;
    float: none;
  }
}

.receipt-content .invoice-wrapper .line-items .total .extra-notes strong {
  display: block;
  margin-bottom: 5px;
  color: #454545;
}

.receipt-content .invoice-wrapper .line-items .total .field {
  margin-bottom: 7px;
  font-size: 14px;
  color: #555;
}

.receipt-content .invoice-wrapper .line-items .total .field.grand-total {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}

.receipt-content .invoice-wrapper .line-items .total .field.grand-total span {
  color: #20a720;
  font-size: 16px;
}

.receipt-content .invoice-wrapper .line-items .total .field span {
  display: inline-block;
  margin-left: 20px;
  min-width: 85px;
  color: #84868a;
  font-size: 15px;
}

.receipt-content .invoice-wrapper .line-items .print {
  margin-top: 50px;
  text-align: center;
}

.receipt-content .invoice-wrapper .line-items .print a i {
  margin-right: 3px;
  font-size: 14px;
}
/* .modal-content {
  margin-top: 45px;
  overflow-y: scroll;
  padding: 10px;
  height: 450px !important;
  border: 1px solid #ccc;
}
.modal-dialog {
  max-width: 650px !important;
} */