.device-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  display: block;
}
.head-summary {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  /* justify-content: space-between; */
  align-items: flex-start;
}
.head-summary li {
  margin-right: 0.3rem;
  padding: 0.75rem;
  flex: 1;
}
.icon-head {
  font-size: 2rem !important;
  top: 10px;
  position: relative;
  margin-left: -2px;
  color: rgb(88, 202, 185);
}
